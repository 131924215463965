import Pagination from "../components/Pagination";
import Card from "../components/Card";
import Filter from "../components/Filter";
import { useEffect, useState } from "react";
import { StyledSearchResults } from "../styles/SearchResults";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import CardConcurso from "../components/CardConcurso";
import ReactGA from "react-ga4";
import SecondSearchBar from "../components/SecondSearchBar";
import {
  estados,
  nivelEducacional,
  faseConcurso,
  vagas,
  salario,
} from "../tools/filters";
import Skeleton from "../UI/Skeleton";
import FilterHide from "../UI/FilterHide";

ReactGA.initialize("G-9EJ6PS0PBE");

export default function SearchResults({
  pagina,
  state,
  setState,
  stage,
  setStage,
  education,
  setEducation,
  vacancy,
  setVacancy,
  wage,
  setWage,
  termoPesquisado,
  setTermoPesquisado,
  setUrlAtual,
  setSearchReset,
  searchReset,
  setOverlay,
  overlay,
}) {
  // Variavéis que vem de app todas desestruturadas
  const {
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
  } = state;
  const {
    setNacional,
    setSaoPaulo,
    setRioDeJaneiro,
    setDistritoFederal,
    setAcre,
    setAlagoas,
    setAmapa,
    setAmazonas,
    setBahia,
    setCeara,
    setEspiritoSanto,
    setGoias,
    setMaranhao,
    setMatoGrosso,
    setMatoGrossoDoSul,
    setMinasGerais,
    setPara,
    setParaiba,
    setParana,
    setPernambuco,
    setPiaui,
    setRioGrandeDoNorte,
    setRioGrandeDoSul,
    setRondonia,
    setRoraima,
    setSantaCatarina,
    setSergipe,
    setTocantins,
  } = setState;
  const { preInscricao, inscricoesAbertas, inscricoesEncerradas } = stage;
  const { setPreInscricao, setInscricoesAbertas, setInscricoesEncerradas } =
    setStage;
  const { superior, fundamental, medio, mestrado, doutorado } = education;
  const { setSuperior, setFundamental, setMedio, setMestrado, setDoutorado } =
    setEducation;
  const {
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
  } = vacancy;
  const {
    setCadastroReserva,
    setVagaUm,
    setVagaDoisDez,
    setVagaDez,
    setVagaVinteCinco,
    setVagaCinquenta,
    setVagaCem,
  } = setVacancy;
  const {
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
  } = wage;
  const {
    setSemRendimentos,
    setSalario1,
    setSalario2,
    setSalario3,
    setSalario4,
    setSalario5,
    setSalario6,
    setSalario7,
    setSalario8,
    setSalario9,
    setSalario10,
    setSalario11,
    setSalario12,
    setSalario13,
    setSalario14,
  } = setWage;

  // Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  // Captura de URL atual
  const location = useLocation();
  const [skeleton, setSkeleton] = useState(false);
  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setUrlAtual(location.pathname);
    setTimeout(() => {
      setSkeleton(true); // Exibe skeleton apenas após X milissegundos
    }, 1000);
  }, []);
  // Permite navegar para uma página de forma programática
  const navigate = useNavigate();
  // Utiliza a biblioteca queryString para analisar a query da URL atual e armazenar os parâmetros como um objeto JavaScript.
  const queryParams = queryString.parse(location.search);

  // Variáveis para o controle de paginação e quantidade de itens na página
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(
    queryParams.offset ? parseInt(queryParams.offset) : 0
  ); // Aqui define offset pra 0 se não tiver nenhum na URL, se tiver um na URL ele define pra aquele

  const [cardLimitPerPage, setCardLimitPerPage] = useState(8);
  // Estou usando esta variável para segurar o que vou renderizar na tela
  const [renderizacao, setRenderizacao] = useState(null);
  // Variável para o texto que aparece antes da contagem
  const [cargoText, setCargoText] = useState("resultados");
  const [foundText, setFoundText] = useState("encontrados");
  // Variável para contador de itens filtrados ou pesquisados
  const [count, setCount] = useState(null);
  // Primera vez que renderiza
  const [firstRender, setFirstRender] = useState(false);
  // Resetando as variáveis
  const [clean, setClean] = useState(false);
  const [open, setOpen] = useState(false);

  let pesquisa = location.pathname.split("/");
  pesquisa = decodeURIComponent(pesquisa[pesquisa.length - 1]);

  useEffect(() => {
    async function resetFilters() {
      setNacional(false);
      setSaoPaulo(false);
      setRioDeJaneiro(false);
      setDistritoFederal(false);
      setAcre(false);
      setAlagoas(false);
      setAmapa(false);
      setAmazonas(false);
      setBahia(false);
      setCeara(false);
      setEspiritoSanto(false);
      setGoias(false);
      setMaranhao(false);
      setMatoGrosso(false);
      setMatoGrossoDoSul(false);
      setMinasGerais(false);
      setPara(false);
      setParaiba(false);
      setParana(false);
      setPernambuco(false);
      setPiaui(false);
      setRioGrandeDoNorte(false);
      setRioGrandeDoSul(false);
      setRondonia(false);
      setRoraima(false);
      setSantaCatarina(false);
      setSergipe(false);
      setTocantins(false);
      setPreInscricao(false);
      setInscricoesAbertas(false);
      setInscricoesEncerradas(false);
      setSuperior(false);
      setFundamental(false);
      setMedio(false);
      setMestrado(false);
      setDoutorado(false);
      setCadastroReserva(false);
      setVagaUm(false);
      setVagaDoisDez(false);
      setVagaDez(false);
      setVagaVinteCinco(false);
      setVagaCinquenta(false);
      setVagaCem(false);
      setSemRendimentos(false);
      setSalario1(false);
      setSalario2(false);
      setSalario3(false);
      setSalario4(false);
      setSalario5(false);
      setSalario6(false);
      setSalario7(false);
      setSalario8(false);
      setSalario9(false);
      setSalario10(false);
      setSalario11(false);
      setSalario12(false);
      setSalario13(false);
      setSalario14(false);
    }

    resetFilters().then(() => {
      setClean(true);
    });
  }, []);

  async function changingVariables() {
    if (JSON.stringify(queryParams) === "{}") {
      setNacional(false);
      setSaoPaulo(false);
      setRioDeJaneiro(false);
      setDistritoFederal(false);
      setAcre(false);
      setAlagoas(false);
      setAmapa(false);
      setAmazonas(false);
      setBahia(false);
      setCeara(false);
      setEspiritoSanto(false);
      setGoias(false);
      setMaranhao(false);
      setMatoGrosso(false);
      setMatoGrossoDoSul(false);
      setMinasGerais(false);
      setPara(false);
      setParaiba(false);
      setParana(false);
      setPernambuco(false);
      setPiaui(false);
      setRioGrandeDoNorte(false);
      setRioGrandeDoSul(false);
      setRondonia(false);
      setRoraima(false);
      setSantaCatarina(false);
      setSergipe(false);
      setTocantins(false);
      setPreInscricao(false);
      setInscricoesAbertas(false);
      setInscricoesEncerradas(false);
      setSuperior(false);
      setFundamental(false);
      setMedio(false);
      setMestrado(false);
      setDoutorado(false);
      setCadastroReserva(false);
      setVagaUm(false);
      setVagaDoisDez(false);
      setVagaDez(false);
      setVagaVinteCinco(false);
      setVagaCinquenta(false);
      setVagaCem(false);
      setSemRendimentos(false);
      setSalario1(false);
      setSalario2(false);
      setSalario3(false);
      setSalario4(false);
      setSalario5(false);
      setSalario6(false);
      setSalario7(false);
      setSalario8(false);
      setSalario9(false);
      setSalario10(false);
      setSalario11(false);
      setSalario12(false);
      setSalario13(false);
      setSalario14(false);
    } else {
      await Promise.all([
        // ESTADOS
        setNacional(queryParams.nacional === "true"),

        setSaoPaulo(queryParams.saoPaulo === "true"),

        setRioDeJaneiro(queryParams.rioDeJaneiro === "true"),

        setDistritoFederal(queryParams.distritoFederal === "true"),

        setAcre(queryParams.acre === "true"),

        setAlagoas(queryParams.alagoas === "true"),

        setAmapa(queryParams.amapa === "true"),

        setAmazonas(queryParams.amazonas === "true"),

        setBahia(queryParams.bahia === "true"),

        setCeara(queryParams.ceara === "true"),

        setEspiritoSanto(queryParams.espiritoSanto === "true"),

        setGoias(queryParams.goias === "true"),

        setMaranhao(queryParams.maranhao === "true"),

        setMatoGrosso(queryParams.matoGrosso === "true"),

        setMatoGrossoDoSul(queryParams.matoGrossoDoSul === "true"),

        setMinasGerais(queryParams.minasGerais === "true"),

        setPara(queryParams.para === "true"),

        setParaiba(queryParams.paraiba === "true"),

        setParana(queryParams.parana === "true"),

        setPernambuco(queryParams.pernambuco === "true"),

        setPiaui(queryParams.piaui === "true"),

        setRioGrandeDoNorte(queryParams.rioGrandeDoNorte === "true"),

        setRioGrandeDoSul(queryParams.rioGrandeDoSul === "true"),

        setRondonia(queryParams.rondonia === "true"),

        setRoraima(queryParams.roraima === "true"),

        setSantaCatarina(queryParams.santaCatarina === "true"),

        setSergipe(queryParams.sergipe === "true"),

        setTocantins(queryParams.tocantins === "true"),

        // ETAPAS
        setPreInscricao(queryParams.preInscricao === "true"),

        setInscricoesAbertas(queryParams.inscricoesAbertas === "true"),

        setInscricoesEncerradas(queryParams.inscricoesEncerradas === "true"),

        // ESCOLARIDADE
        setSuperior(queryParams.superior === "true"),

        setFundamental(queryParams.fundamental === "true"),

        setMedio(queryParams.medio === "true"),

        setMestrado(queryParams.mestrado === "true"),

        setDoutorado(queryParams.doutorado === "true"),

        // VAGAS
        setCadastroReserva(queryParams.cadastroReserva === "true"),

        setVagaUm(queryParams.vagaUm === "true"),

        setVagaDoisDez(queryParams.vagaDoisDez === "true"),

        setVagaDez(queryParams.vagaDez === "true"),

        setVagaVinteCinco(queryParams.vagaVinteCinco === "true"),

        setVagaCinquenta(queryParams.vagaCinquenta === "true"),

        setVagaCem(queryParams.vagaCem === "true"),

        // SALÁRIO
        setSemRendimentos(queryParams.semRendimentos === "true"),

        setSalario1(queryParams.salario1 === "true"),

        setSalario2(queryParams.salario2 === "true"),

        setSalario3(queryParams.salario3 === "true"),

        setSalario4(queryParams.salario4 === "true"),

        setSalario5(queryParams.salario5 === "true"),

        setSalario6(queryParams.salario6 === "true"),

        setSalario7(queryParams.salario7 === "true"),

        setSalario8(queryParams.salario8 === "true"),

        setSalario9(queryParams.salario9 === "true"),

        setSalario10(queryParams.salario10 === "true"),

        setSalario11(queryParams.salario11 === "true"),

        setSalario12(queryParams.salario12 === "true"),

        setSalario13(queryParams.salario13 === "true"),

        setSalario14(queryParams.salario14 === "true"),

        setOffset(queryParams.offset),
      ]);
    }
  }

  // De acordo com a URL seta as variaveis na primeira vez que o componente carrega
  useEffect(() => {
    if (clean) {
      changingVariables().then(() => setFirstRender(true));
    }
  }, [clean]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1817 && window.innerWidth > 1439) {
        setCardLimitPerPage(6);
      } else if (window.innerWidth <= 1439) {
        setCardLimitPerPage(4);
      } else {
        setCardLimitPerPage(8);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Esta função muda a url de acordo com as variaveis e muda o link
  function changeURL() {
    const newQueryParams = {
      offset,
    };
    if (nacional) newQueryParams.nacional = nacional;
    if (saoPaulo) newQueryParams.saoPaulo = saoPaulo;
    if (rioDeJaneiro) newQueryParams.rioDeJaneiro = rioDeJaneiro;
    if (distritoFederal) newQueryParams.distritoFederal = distritoFederal;
    if (acre) newQueryParams.acre = acre;
    if (alagoas) newQueryParams.alagoas = alagoas;
    if (amapa) newQueryParams.amapa = amapa;
    if (amazonas) newQueryParams.amazonas = amazonas;
    if (bahia) newQueryParams.bahia = bahia;
    if (ceara) newQueryParams.ceara = ceara;
    if (espiritoSanto) newQueryParams.espiritoSanto = espiritoSanto;
    if (goias) newQueryParams.goias = goias;
    if (maranhao) newQueryParams.maranhao = maranhao;
    if (matoGrosso) newQueryParams.matoGrosso = matoGrosso;
    if (matoGrossoDoSul) newQueryParams.matoGrossoDoSul = matoGrossoDoSul;
    if (minasGerais) newQueryParams.minasGerais = minasGerais;
    if (para) newQueryParams.para = para;
    if (paraiba) newQueryParams.paraiba = paraiba;
    if (parana) newQueryParams.parana = parana;
    if (pernambuco) newQueryParams.pernambuco = pernambuco;
    if (piaui) newQueryParams.piaui = piaui;
    if (rioGrandeDoNorte) newQueryParams.rioGrandeDoNorte = rioGrandeDoNorte;
    if (rioGrandeDoSul) newQueryParams.rioGrandeDoSul = rioGrandeDoSul;
    if (rondonia) newQueryParams.rondonia = rondonia;
    if (roraima) newQueryParams.roraima = roraima;
    if (santaCatarina) newQueryParams.santaCatarina = santaCatarina;
    if (sergipe) newQueryParams.sergipe = sergipe;
    if (tocantins) newQueryParams.tocantins = tocantins;

    if (preInscricao) newQueryParams.preInscricao = preInscricao;
    if (inscricoesAbertas) newQueryParams.inscricoesAbertas = inscricoesAbertas;
    if (inscricoesEncerradas)
      newQueryParams.inscricoesEncerradas = inscricoesEncerradas;
    if (superior) newQueryParams.superior = superior;
    if (fundamental) newQueryParams.fundamental = fundamental;
    if (medio) newQueryParams.medio = medio;
    if (mestrado) newQueryParams.mestrado = mestrado;
    if (doutorado) newQueryParams.doutorado = doutorado;
    if (cadastroReserva) newQueryParams.cadastroReserva = cadastroReserva;
    if (vagaUm) newQueryParams.vagaUm = vagaUm;
    if (vagaDoisDez) newQueryParams.vagaDoisDez = vagaDoisDez;
    if (vagaDez) newQueryParams.vagaDez = vagaDez;
    if (vagaVinteCinco) newQueryParams.vagaVinteCinco = vagaVinteCinco;
    if (vagaCinquenta) newQueryParams.vagaCinquenta = vagaCinquenta;
    if (vagaCem) newQueryParams.vagaCem = vagaCem;
    if (semRendimentos) newQueryParams.semRendimentos = semRendimentos;
    if (salario1) newQueryParams.salario1 = salario1;
    if (salario2) newQueryParams.salario2 = salario2;
    if (salario3) newQueryParams.salario3 = salario3;
    if (salario4) newQueryParams.salario4 = salario4;
    if (salario5) newQueryParams.salario5 = salario5;
    if (salario6) newQueryParams.salario6 = salario6;
    if (salario7) newQueryParams.salario7 = salario7;
    if (salario8) newQueryParams.salario8 = salario8;
    if (salario9) newQueryParams.salario9 = salario9;
    if (salario10) newQueryParams.salario10 = salario10;
    if (salario11) newQueryParams.salario11 = salario11;
    if (salario12) newQueryParams.salario12 = salario12;
    if (salario13) newQueryParams.salario13 = salario13;
    if (salario14) newQueryParams.salario14 = salario14;
    // searchString passa por um stringfy para converter os objetos que bateram com a URL em chave valor na URL iguais
    const searchString = queryString.stringify(newQueryParams);
    // Em seguida ele navega pra esse link salvando assim o estado que está
    navigate(`${location.pathname}?${searchString}`);
  }

  async function requisicao(apiLink) {
    await fetch(apiLink, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setRenderizacao(response.results);

        response.results.length > 0 ? setCount(response.count) : setCount(0);

        if (response.count <= 1) {
          setCargoText("resultado");
          setFoundText("encontrado");
        } else {
          setCargoText("resultados");
          setFoundText("encontrados");
        }
      });
  }

  // Cria uma matriz de objetos chamada Filtros, cada objeto tem uma chave principal que descreve o tipo de filtro, por exemplo Estados, ensino, etapas...
  // Dentro de cada objeto tem uma chave chamada "chave" que é o prefixo usado na API para aquele filtro
  // Em valores dentro de cada objeto temos uma nova matriz, onde cada objeto é um valor especifico desse filtro, por exemplo são paulo é 33 no objeto estados

  const filtros = [
    {
      estados: {
        chave: "&location_work_state=",
        valores: [
          { estado: "12", variavel: [nacional] },
          { estado: "35", variavel: [saoPaulo] },
          { estado: "33", variavel: [rioDeJaneiro] },
          { estado: "53", variavel: [distritoFederal] },
          { estado: "12", variavel: [acre] },
          { estado: "27", variavel: [alagoas] },
          { estado: "16", variavel: [amapa] },
          { estado: "13", variavel: [amazonas] },
          { estado: "29", variavel: [bahia] },
          { estado: "23", variavel: [ceara] },
          { estado: "32", variavel: [espiritoSanto] },
          { estado: "52", variavel: [goias] },
          { estado: "21", variavel: [maranhao] },
          { estado: "51", variavel: [matoGrosso] },
          { estado: "50", variavel: [matoGrossoDoSul] },
          { estado: "31", variavel: [minasGerais] },
          { estado: "15", variavel: [para] },
          { estado: "25", variavel: [paraiba] },
          { estado: "41", variavel: [parana] },
          { estado: "26", variavel: [pernambuco] },
          { estado: "22", variavel: [piaui] },
          { estado: "24", variavel: [rioGrandeDoNorte] },
          { estado: "43", variavel: [rioGrandeDoSul] },
          { estado: "11", variavel: [rondonia] },
          { estado: "14", variavel: [roraima] },
          { estado: "42", variavel: [santaCatarina] },
          { estado: "28", variavel: [sergipe] },
          { estado: "17", variavel: [tocantins] },
        ],
      },
    },
    {
      ensino: {
        chave: "&require_schooling=",
        valores: [
          { valor: "4", variaveis: [superior] },
          { valor: "1", variaveis: [fundamental] },
          { valor: "2", variaveis: [medio] },
          { valor: "7", variaveis: [mestrado] },
          { valor: "8", variaveis: [doutorado] },
        ],
      },
    },
    {
      etapas: {
        chave: "&stage=",
        valores: [
          { valor: "1", variaveis: [preInscricao] },
          { valor: "2", variaveis: [inscricoesAbertas] },
          { valor: "3", variaveis: [inscricoesEncerradas] },
        ],
      },
    },
    {
      vagas1: {
        chave: "&vacancy_reservation=",
        valores: [{ valor: "true", variaveis: [cadastroReserva] }],
      },
    },
    {
      vagas2: {
        chave: "&vacancy_range=",
        valores: [
          { valor: "1%2C1", variaveis: [vagaUm] },
          { valor: "2%2C10", variaveis: [vagaDoisDez] },
          { valor: "10%2C25", variaveis: [vagaDez] },
          { valor: "25%2C50", variaveis: [vagaVinteCinco] },
          { valor: "50%2C100", variaveis: [vagaCinquenta] },
          { valor: "100%2C3000000", variaveis: [vagaCem] },
        ],
      },
    },
    {
      salario: {
        chave: "&wage_range=",
        valores: [
          { valor: "0%2C0", variaveis: [semRendimentos] },
          { valor: "0%2C1000", variaveis: [salario1] },
          { valor: "1000%2C1500", variaveis: [salario2] },
          { valor: "1500%2C2000", variaveis: [salario3] },
          { valor: "2000%2C2500", variaveis: [salario4] },
          { valor: "2500%2C3000", variaveis: [salario5] },
          { valor: "3000%2C4000", variaveis: [salario6] },
          { valor: "4000%2C5000", variaveis: [salario7] },
          { valor: "5000%2C7500", variaveis: [salario8] },
          { valor: "7500%2C10000", variaveis: [salario9] },
          { valor: "10000%2C15000", variaveis: [salario10] },
          { valor: "15000%2C20000", variaveis: [salario11] },
          { valor: "20000%2C30000", variaveis: [salario12] },
          { valor: "30000%2C40000", variaveis: [salario13] },
          { valor: "40000%2C600000", variaveis: [salario14] },
        ],
      },
    },
  ];

  // Esta função tem o objetivo de estabelecer uma query para a API. Ela inicia a variavel com a requisição básica para a API,
  // ela percorre a matriz Filtros e se uma das variáveis dos valores for true ela muda a variavel para o valor que ela já tinha + os novos parâmetros, no fim retorna a query
  function buildQueryFromFilters() {
    let queryForApi = `${URL}/search/${pagina}/${pesquisa}/?limit=${cardLimitPerPage}&offset=${offset}`;

    // Estados
    for (let i = 0; i < filtros[0].estados.valores.length; i++) {
      if (filtros[0].estados.valores[i].variavel[0]) {
        queryForApi =
          queryForApi +
          filtros[0].estados.chave +
          filtros[0].estados.valores[i].estado;
      }
    }

    // Ensino
    for (let i = 0; i < filtros[1].ensino.valores.length; i++) {
      if (filtros[1].ensino.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[1].ensino.chave +
          filtros[1].ensino.valores[i].valor;
      }
    }

    // Etapas
    for (let i = 0; i < filtros[2].etapas.valores.length; i++) {
      if (filtros[2].etapas.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[2].etapas.chave +
          filtros[2].etapas.valores[i].valor;
      }
    }

    // Vagas
    if (filtros[3].vagas1.valores[0].variaveis[0]) {
      queryForApi =
        queryForApi +
        filtros[3].vagas1.chave +
        filtros[3].vagas1.valores[0].valor;
    }
    for (let i = 0; i < filtros[4].vagas2.valores.length; i++) {
      if (filtros[4].vagas2.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[4].vagas2.chave +
          filtros[4].vagas2.valores[i].valor;
      }
    }

    // Salario
    for (let i = 0; i < filtros[5].salario.valores.length; i++) {
      if (filtros[5].salario.valores[i].variaveis[0]) {
        queryForApi =
          queryForApi +
          filtros[5].salario.chave +
          filtros[5].salario.valores[i].valor;
      }
    }
    return queryForApi;
  }

  useEffect(() => {
    if (firstRender) {
      let apiRequisition = buildQueryFromFilters();
      requisicao(apiRequisition);
      changeURL();
    }
  }, [
    preInscricao,
    inscricoesAbertas,
    inscricoesEncerradas,
    superior,
    fundamental,
    medio,
    mestrado,
    doutorado,
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
    offset,
    firstRender,
    searchReset,
  ]);

  useEffect(() => {
    if (pagina === "offices") {
      document.title = "Resultados Cargos - Alô Concursos";
    } else {
      document.title = "Resultados Concursos - Alô Concursos";
    }
  }, [renderizacao, pagina]);

  // Quando os filtros forem alterados o offset muda pra 0 impedindo que continue em páginas posteriores
  useEffect(() => {
    setOffset(0);
  }, [
    preInscricao,
    inscricoesAbertas,
    inscricoesEncerradas,
    superior,
    fundamental,
    medio,
    mestrado,
    doutorado,
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
  ]);

  return (
    <StyledSearchResults className="SearchResults">
      <SecondSearchBar
        page={pagina === "offices" ? "Cargos" : "Concursos"}
        setTermoPesquisado={setTermoPesquisado}
        termoPesquisado={termoPesquisado}
        setSearchReset={setSearchReset}
        searchReset={searchReset}
      />
      <FilterHide setOpen={setOpen} open={open} />
      <section className="SectionCard">
        {pagina === "offices" ? (
          window.innerWidth <= 969 ? (
            open && (
              <Filter
                overlay={overlay}
                setOverlay={setOverlay}
                estados={estados}
                nivelEducacional={nivelEducacional}
                faseConcurso={faseConcurso}
                vagas={vagas}
                salario={salario}
                setPreInscricao={setPreInscricao}
                preInscricao={preInscricao}
                setInscricoesAbertas={setInscricoesAbertas}
                inscricoesAbertas={inscricoesAbertas}
                setInscricoesEncerradas={setInscricoesEncerradas}
                inscricoesEncerradas={inscricoesEncerradas}
                setSuperior={setSuperior}
                superior={superior}
                setFundamental={setFundamental}
                fundamental={fundamental}
                setMedio={setMedio}
                medio={medio}
                setMestrado={setMestrado}
                mestrado={mestrado}
                setDoutorado={setDoutorado}
                doutorado={doutorado}
                setCadastroReserva={setCadastroReserva}
                cadastroReserva={cadastroReserva}
                vagaUm={vagaUm}
                setVagaUm={setVagaUm}
                vagaDoisDez={vagaDoisDez}
                setVagaDoisDez={setVagaDoisDez}
                vagaDez={vagaDez}
                setVagaDez={setVagaDez}
                vagaVinteCinco={vagaVinteCinco}
                setVagaVinteCinco={setVagaVinteCinco}
                vagaCinquenta={vagaCinquenta}
                setVagaCinquenta={setVagaCinquenta}
                vagaCem={vagaCem}
                setVagaCem={setVagaCem}
                semRendimentos={semRendimentos}
                setSemRendimentos={setSemRendimentos}
                salario1={salario1}
                setSalario1={setSalario1}
                salario2={salario2}
                setSalario2={setSalario2}
                salario3={salario3}
                setSalario3={setSalario3}
                salario4={salario4}
                setSalario4={setSalario4}
                salario5={salario5}
                setSalario5={setSalario5}
                salario6={salario6}
                setSalario6={setSalario6}
                salario7={salario7}
                setSalario7={setSalario7}
                salario8={salario8}
                setSalario8={setSalario8}
                salario9={salario9}
                setSalario9={setSalario9}
                salario10={salario10}
                setSalario10={setSalario10}
                salario11={salario11}
                setSalario11={setSalario11}
                salario12={salario12}
                setSalario12={setSalario12}
                salario13={salario13}
                setSalario13={setSalario13}
                salario14={salario14}
                setSalario14={setSalario14}
                nacional={nacional}
                saoPaulo={saoPaulo}
                rioDeJaneiro={rioDeJaneiro}
                distritoFederal={distritoFederal}
                acre={acre}
                alagoas={alagoas}
                amapa={amapa}
                amazonas={amazonas}
                bahia={bahia}
                ceara={ceara}
                espiritoSanto={espiritoSanto}
                goias={goias}
                maranhao={maranhao}
                matoGrosso={matoGrosso}
                matoGrossoDoSul={matoGrossoDoSul}
                minasGerais={minasGerais}
                para={para}
                paraiba={paraiba}
                parana={parana}
                pernambuco={pernambuco}
                piaui={piaui}
                rioGrandeDoNorte={rioGrandeDoNorte}
                rioGrandeDoSul={rioGrandeDoSul}
                rondonia={rondonia}
                roraima={roraima}
                santaCatarina={santaCatarina}
                sergipe={sergipe}
                tocantins={tocantins}
                setNacional={setNacional}
                setSaoPaulo={setSaoPaulo}
                setRioDeJaneiro={setRioDeJaneiro}
                setDistritoFederal={setDistritoFederal}
                setAcre={setAcre}
                setAlagoas={setAlagoas}
                setAmapa={setAmapa}
                setAmazonas={setAmazonas}
                setBahia={setBahia}
                setCeara={setCeara}
                setEspiritoSanto={setEspiritoSanto}
                setGoias={setGoias}
                setMaranhao={setMaranhao}
                setMatoGrosso={setMatoGrosso}
                setMatoGrossoDoSul={setMatoGrossoDoSul}
                setMinasGerais={setMinasGerais}
                setPara={setPara}
                setParaiba={setParaiba}
                setParana={setParana}
                setPernambuco={setPernambuco}
                setPiaui={setPiaui}
                setRioGrandeDoNorte={setRioGrandeDoNorte}
                setRioGrandeDoSul={setRioGrandeDoSul}
                setRondonia={setRondonia}
                setRoraima={setRoraima}
                setSantaCatarina={setSantaCatarina}
                setSergipe={setSergipe}
                setTocantins={setTocantins}
              />
            )
          ) : (
            <Filter
              overlay={overlay}
              setOverlay={setOverlay}
              estados={estados}
              nivelEducacional={nivelEducacional}
              faseConcurso={faseConcurso}
              vagas={vagas}
              salario={salario}
              setPreInscricao={setPreInscricao}
              preInscricao={preInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              inscricoesAbertas={inscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              inscricoesEncerradas={inscricoesEncerradas}
              setSuperior={setSuperior}
              superior={superior}
              setFundamental={setFundamental}
              fundamental={fundamental}
              setMedio={setMedio}
              medio={medio}
              setMestrado={setMestrado}
              mestrado={mestrado}
              setDoutorado={setDoutorado}
              doutorado={doutorado}
              setCadastroReserva={setCadastroReserva}
              cadastroReserva={cadastroReserva}
              vagaUm={vagaUm}
              setVagaUm={setVagaUm}
              vagaDoisDez={vagaDoisDez}
              setVagaDoisDez={setVagaDoisDez}
              vagaDez={vagaDez}
              setVagaDez={setVagaDez}
              vagaVinteCinco={vagaVinteCinco}
              setVagaVinteCinco={setVagaVinteCinco}
              vagaCinquenta={vagaCinquenta}
              setVagaCinquenta={setVagaCinquenta}
              vagaCem={vagaCem}
              setVagaCem={setVagaCem}
              semRendimentos={semRendimentos}
              setSemRendimentos={setSemRendimentos}
              salario1={salario1}
              setSalario1={setSalario1}
              salario2={salario2}
              setSalario2={setSalario2}
              salario3={salario3}
              setSalario3={setSalario3}
              salario4={salario4}
              setSalario4={setSalario4}
              salario5={salario5}
              setSalario5={setSalario5}
              salario6={salario6}
              setSalario6={setSalario6}
              salario7={salario7}
              setSalario7={setSalario7}
              salario8={salario8}
              setSalario8={setSalario8}
              salario9={salario9}
              setSalario9={setSalario9}
              salario10={salario10}
              setSalario10={setSalario10}
              salario11={salario11}
              setSalario11={setSalario11}
              salario12={salario12}
              setSalario12={setSalario12}
              salario13={salario13}
              setSalario13={setSalario13}
              salario14={salario14}
              setSalario14={setSalario14}
              nacional={nacional}
              saoPaulo={saoPaulo}
              rioDeJaneiro={rioDeJaneiro}
              distritoFederal={distritoFederal}
              acre={acre}
              alagoas={alagoas}
              amapa={amapa}
              amazonas={amazonas}
              bahia={bahia}
              ceara={ceara}
              espiritoSanto={espiritoSanto}
              goias={goias}
              maranhao={maranhao}
              matoGrosso={matoGrosso}
              matoGrossoDoSul={matoGrossoDoSul}
              minasGerais={minasGerais}
              para={para}
              paraiba={paraiba}
              parana={parana}
              pernambuco={pernambuco}
              piaui={piaui}
              rioGrandeDoNorte={rioGrandeDoNorte}
              rioGrandeDoSul={rioGrandeDoSul}
              rondonia={rondonia}
              roraima={roraima}
              santaCatarina={santaCatarina}
              sergipe={sergipe}
              tocantins={tocantins}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
            />
          )
        ) : window.innerWidth <= 969 ? (
          open && (
            <Filter
              setOverlay={setOverlay}
              overlay={overlay}
              faseConcurso={faseConcurso}
              vagas={vagas}
              salario={salario}
              setPreInscricao={setPreInscricao}
              preInscricao={preInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              inscricoesAbertas={inscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              inscricoesEncerradas={inscricoesEncerradas}
              setCadastroReserva={setCadastroReserva}
              cadastroReserva={cadastroReserva}
              vagaUm={vagaUm}
              setVagaUm={setVagaUm}
              vagaDoisDez={vagaDoisDez}
              setVagaDoisDez={setVagaDoisDez}
              vagaDez={vagaDez}
              setVagaDez={setVagaDez}
              vagaVinteCinco={vagaVinteCinco}
              setVagaVinteCinco={setVagaVinteCinco}
              vagaCinquenta={vagaCinquenta}
              setVagaCinquenta={setVagaCinquenta}
              vagaCem={vagaCem}
              setVagaCem={setVagaCem}
              semRendimentos={semRendimentos}
              setSemRendimentos={setSemRendimentos}
              salario1={salario1}
              setSalario1={setSalario1}
              salario2={salario2}
              setSalario2={setSalario2}
              salario3={salario3}
              setSalario3={setSalario3}
              salario4={salario4}
              setSalario4={setSalario4}
              salario5={salario5}
              setSalario5={setSalario5}
              salario6={salario6}
              setSalario6={setSalario6}
              salario7={salario7}
              setSalario7={setSalario7}
              salario8={salario8}
              setSalario8={setSalario8}
              salario9={salario9}
              setSalario9={setSalario9}
              salario10={salario10}
              setSalario10={setSalario10}
              salario11={salario11}
              setSalario11={setSalario11}
              salario12={salario12}
              setSalario12={setSalario12}
              salario13={salario13}
              setSalario13={setSalario13}
              salario14={salario14}
              setSalario14={setSalario14}
              nacional={nacional}
              saoPaulo={saoPaulo}
              rioDeJaneiro={rioDeJaneiro}
              distritoFederal={distritoFederal}
              acre={acre}
              alagoas={alagoas}
              amapa={amapa}
              amazonas={amazonas}
              bahia={bahia}
              ceara={ceara}
              espiritoSanto={espiritoSanto}
              goias={goias}
              maranhao={maranhao}
              matoGrosso={matoGrosso}
              matoGrossoDoSul={matoGrossoDoSul}
              minasGerais={minasGerais}
              para={para}
              paraiba={paraiba}
              parana={parana}
              pernambuco={pernambuco}
              piaui={piaui}
              rioGrandeDoNorte={rioGrandeDoNorte}
              rioGrandeDoSul={rioGrandeDoSul}
              rondonia={rondonia}
              roraima={roraima}
              santaCatarina={santaCatarina}
              sergipe={sergipe}
              tocantins={tocantins}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
            />
          )
        ) : (
          <Filter
            setOverlay={setOverlay}
            overlay={overlay}
            faseConcurso={faseConcurso}
            vagas={vagas}
            salario={salario}
            setPreInscricao={setPreInscricao}
            preInscricao={preInscricao}
            setInscricoesAbertas={setInscricoesAbertas}
            inscricoesAbertas={inscricoesAbertas}
            setInscricoesEncerradas={setInscricoesEncerradas}
            inscricoesEncerradas={inscricoesEncerradas}
            setCadastroReserva={setCadastroReserva}
            cadastroReserva={cadastroReserva}
            vagaUm={vagaUm}
            setVagaUm={setVagaUm}
            vagaDoisDez={vagaDoisDez}
            setVagaDoisDez={setVagaDoisDez}
            vagaDez={vagaDez}
            setVagaDez={setVagaDez}
            vagaVinteCinco={vagaVinteCinco}
            setVagaVinteCinco={setVagaVinteCinco}
            vagaCinquenta={vagaCinquenta}
            setVagaCinquenta={setVagaCinquenta}
            vagaCem={vagaCem}
            setVagaCem={setVagaCem}
            semRendimentos={semRendimentos}
            setSemRendimentos={setSemRendimentos}
            salario1={salario1}
            setSalario1={setSalario1}
            salario2={salario2}
            setSalario2={setSalario2}
            salario3={salario3}
            setSalario3={setSalario3}
            salario4={salario4}
            setSalario4={setSalario4}
            salario5={salario5}
            setSalario5={setSalario5}
            salario6={salario6}
            setSalario6={setSalario6}
            salario7={salario7}
            setSalario7={setSalario7}
            salario8={salario8}
            setSalario8={setSalario8}
            salario9={salario9}
            setSalario9={setSalario9}
            salario10={salario10}
            setSalario10={setSalario10}
            salario11={salario11}
            setSalario11={setSalario11}
            salario12={salario12}
            setSalario12={setSalario12}
            salario13={salario13}
            setSalario13={setSalario13}
            salario14={salario14}
            setSalario14={setSalario14}
            nacional={nacional}
            saoPaulo={saoPaulo}
            rioDeJaneiro={rioDeJaneiro}
            distritoFederal={distritoFederal}
            acre={acre}
            alagoas={alagoas}
            amapa={amapa}
            amazonas={amazonas}
            bahia={bahia}
            ceara={ceara}
            espiritoSanto={espiritoSanto}
            goias={goias}
            maranhao={maranhao}
            matoGrosso={matoGrosso}
            matoGrossoDoSul={matoGrossoDoSul}
            minasGerais={minasGerais}
            para={para}
            paraiba={paraiba}
            parana={parana}
            pernambuco={pernambuco}
            piaui={piaui}
            rioGrandeDoNorte={rioGrandeDoNorte}
            rioGrandeDoSul={rioGrandeDoSul}
            rondonia={rondonia}
            roraima={roraima}
            santaCatarina={santaCatarina}
            sergipe={sergipe}
            tocantins={tocantins}
            setNacional={setNacional}
            setSaoPaulo={setSaoPaulo}
            setRioDeJaneiro={setRioDeJaneiro}
            setDistritoFederal={setDistritoFederal}
            setAcre={setAcre}
            setAlagoas={setAlagoas}
            setAmapa={setAmapa}
            setAmazonas={setAmazonas}
            setBahia={setBahia}
            setCeara={setCeara}
            setEspiritoSanto={setEspiritoSanto}
            setGoias={setGoias}
            setMaranhao={setMaranhao}
            setMatoGrosso={setMatoGrosso}
            setMatoGrossoDoSul={setMatoGrossoDoSul}
            setMinasGerais={setMinasGerais}
            setPara={setPara}
            setParaiba={setParaiba}
            setParana={setParana}
            setPernambuco={setPernambuco}
            setPiaui={setPiaui}
            setRioGrandeDoNorte={setRioGrandeDoNorte}
            setRioGrandeDoSul={setRioGrandeDoSul}
            setRondonia={setRondonia}
            setRoraima={setRoraima}
            setSantaCatarina={setSantaCatarina}
            setSergipe={setSergipe}
            setTocantins={setTocantins}
          />
        )}

        <div className="CardMenu">
          {count === 0 || count ? (
            <h3 className="tituloPrincipal">
              {count} {cargoText} {foundText} para {termoPesquisado}
            </h3>
          ) : (
            <Skeleton width={"570.203px"} height={"38px"} />
          )}

          <section className="cardsGrid">
            {pagina === "offices"
              ? renderizacao
                ? renderizacao.lenght === 0
                  ? null
                  : renderizacao.map((item, index) => {
                      return (
                        <Card
                          working_hours={item.working_hours}
                          key={index}
                          prefeitura={item.contest.short_name}
                          nomeDoCargo={item.display_name}
                          nomeCargoEspecifico={item.short_name}
                          inscricoesAbertas={item.contest.registration_opening}
                          inscricoesEncerradas={
                            item.contest.registration_deadline
                          }
                          diaDaProva={item.contest.exam_date}
                          vagas={item.vacancies}
                          salario={item.wage}
                          banca={item.contest.organization.short_name}
                          ensino={item.require_schooling.name}
                          local={
                            item.location_work_override_detail
                              ? item.location_work_override_detail.name
                                ? item.location_work_override_detail.name
                                : item.contest.location_work_name
                              : item.contest.location_work_name
                          }
                          taxa={
                            item.registration_fee_override
                              ? item.registration_fee_override
                              : item.contest.registration_fee
                          }
                          icone={
                            item.icon
                              ? item.icon.svg_url
                              : "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
                          }
                          stage={item.contest.stage.name}
                          id={item.contest.id}
                          slug={item.contest.slug}
                          tags={item.tags ? item.tags : null}
                        />
                      );
                    })
                : skeleton
                ? Array.from({ length: cardLimitPerPage }).map((_, index) => {
                    return (
                      <Skeleton key={index} width={"100%"} height={"100%"} />
                    );
                  })
                : null
              : renderizacao
              ? renderizacao.lenght === 0
                ? null
                : renderizacao.map((item, index) => {
                    return (
                      <CardConcurso
                        name={item.short_name}
                        location={
                          item.location_work_detail
                            ? item.location_work_detail.name
                            : "Não informado"
                        }
                        wageRange={item.max_wage}
                        vacancy={item.vacancies}
                        registration_opening={item.registration_opening}
                        registrationDeadline={item.registration_deadline}
                        id={item.id}
                        slug={item.slug}
                        imagemConcurso={item.icon ? item.icon.svg_url : null}
                        contest_number={item.contest_number}
                        key={index}
                      />
                    );
                  })
              : skeleton
              ? Array.from({ length: cardLimitPerPage }).map((_, index) => {
                  return (
                    <Skeleton key={index} width={"100%"} height={"100%"} />
                  );
                })
              : null}
          </section>
        </div>

        {count ? (
          <Pagination
            setCurrentPage={setCurrentPage}
            limit={cardLimitPerPage}
            total={count}
            offset={offset}
            setOffset={setOffset}
          />
        ) : skeleton ? (
          <Skeleton
            width={`98%`}
            height={"4.3rem"}
            additionalStyle={{
              gridColumn: "2 / 3",
              margin: "0 2.1337rem",
            }}
          />
        ) : null}
      </section>
    </StyledSearchResults>
  );
}
