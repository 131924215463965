import React from "react";
import SecondSearchBar from "./SecondSearchBar";
import searchBackground from "../assets/searchBackground.png";
import { StyledSearchBox } from "../styles/SearchBox";

function SearchBox({ setSearchReset, setTermoPesquisado }) {
  return (
    <StyledSearchBox className="secondSection">
      <div
        className="box"
        style={{ backgroundImage: `url(${searchBackground})` }}
      >
        <div className="flexSearchBlog">
          <div className="tittleAndText">
            <h2>Busque por cargos ou concursos</h2>
            <p>Faça sua busca usando a barra de pesquisa abaixo.</p>
          </div>
          <SecondSearchBar
            setSearchReset={setSearchReset}
            setTermoPesquisado={setTermoPesquisado}
            page="Blog"
            width="100%"
          />
        </div>
      </div>
    </StyledSearchBox>
  );
}

export default SearchBox;
