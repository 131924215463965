const info = "Informações completas sobre os concursos";

export const Basic = [
  {
    id: 1,
    text: "Buscas ilimitadas",
    status: "active",
  },
  {
    id: 2,
    text: "Busca por cargos e concursos",
    status: "active",
  },
  {
    id: 3,
    text: info,
    status: "active",
  },
  {
    id: 3.11,
    text: "Newsletter",
    status: "active",
  },

  {
    id: 4,
    text: "Acesso a todos os filtros",
    status: "active",
  },
  {
    id: 3.1,
    text: "Sem anúncios",
    status: "disable",
  },
  {
    id: 5,
    text: "25% de desconto",
    status: "disable",
  },
];

export const Premium = [
  {
    id: 6,
    text: "Buscas ilimitadas",
    status: "active",
  },
  {
    id: 7,
    text: "Busca por cargos e concursos",
    status: "active",
  },
  {
    id: 8,
    text: info,
    status: "active",
  },
  {
    id: 8.11,
    text: "Newsletter",
    status: "active",
  },

  {
    id: 9,
    text: "Acesso a todos os filtros",
    status: "active",
  },
  {
    id: 8.1,
    text: "Sem anúncios",
    status: "active",
  },

  {
    id: 10,
    text: "25% de desconto",
    status: "disable",
  },
];

export const PremiumPlus = [
  {
    id: 11,
    text: "Buscas ilimitadas",
    status: "active",
  },
  {
    id: 12,
    text: "Busca por cargos e concursos",
    status: "active",
  },
  {
    id: 13,
    text: info,
    status: "active",
  },
  {
    id: 13.11,
    text: "Newsletter",
    status: "active",
  },
  {
    id: 14,
    text: "Acesso a todos os filtros",
    status: "active",
  },
  {
    id: 13.1,
    text: "Sem anúncios",
    status: "active",
  },

  {
    id: 15,
    text: "25% de desconto",
    status: "bold",
  },
];
