import React from "react";
import { StyledBlogHomepage } from "../styles/BlogHomepage";
import BlogCard from "../components/BlogCard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBox from "../components/SearchBox";
import { Link } from "react-router-dom";

function BlogHomepage({ setUrlAtual, setTermoPesquisado, setSearchReset }) {
  const [renderizacao, setRenderizacao] = useState(null);
  // * Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;
  async function requisicao(apiLink) {
    await fetch(apiLink, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setRenderizacao(response);
      });
  }

  const location = useLocation();

  useEffect(() => {
    document.title = "Blog da Alô Concursos";
    setUrlAtual(location.pathname);
    requisicao(`${URL}/blog/blogitems/`);
  }, []);

  useEffect(() => {}, [renderizacao]);

  return (
    <StyledBlogHomepage>
      <section className="firstSection">
        <div className="boxTittle">
          <h1>Blog da Alô Concursos</h1>
          <p>Fique atualizado sobre o mundo dos concursos público</p>
        </div>
        {renderizacao && (
          <Link
            style={{ textDecoration: "none" }}
            to={`/blog/${renderizacao[0].blog_post}`}
            className="mainPost"
          >
            <img
              src={renderizacao[0].main_image}
              alt={renderizacao[0].main_image_description}
            />
            <div className="tittleAndText">
              <h2>
                Explorando as últimas pesquisas: estudos chave que estão
                moldando nossa compreensão em 2024
              </h2>
              <p>28 de novembro de 2024</p>
            </div>
          </Link>
        )}

        <div className="posts">
          <h2>Últimos artigos</h2>
          <div className="gridCards">
            {renderizacao?.slice(1).map((item, index) => {
              return (
                <BlogCard
                  id={item.blog_post}
                  key={index}
                  img={item.main_image}
                  imgName={item.main_image_description}
                  tittle={item.title}
                  subttitle={item.subtitle}
                />
              );
            })}
          </div>
        </div>
        <div className="loadMore">
          <button>
            <p>Carregar mais</p>
          </button>
        </div>
      </section>
      <SearchBox
        setTermoPesquisado={setTermoPesquisado}
        setSearchReset={setSearchReset}
      />
    </StyledBlogHomepage>
  );
}

export default BlogHomepage;
