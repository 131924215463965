import styled from "styled-components";

export const StyledBlogCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  min-width: 398px;
  max-width: 398px;
  img {
    width: 100%;
    height: 225px;
  }
  h3 {
    /* min-height: 50.41px; */
    overflow: hidden;
    color: var(--Grey-700, #33394d);
    text-overflow: ellipsis;

    /* Text/Extra Large/Bold */
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
  }
  p {
    overflow: hidden;
    color: var(--Grey-600, #495066);
    text-overflow: ellipsis;

    /* Text/Large/Regular */
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }

  @media (max-width: 1700px) {
    min-width: 411px;
    max-width: 411px;
  }
  @media (max-width: 437px) {
    min-width: 328px;
    max-width: 328px;
  }
`;
