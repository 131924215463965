import styled from "styled-components";

export const StyledInnerPostBlog = styled.main`
  min-height: calc(100vh - var(--NewFooterHeight) - var(--NavHeight));
  width: 92%;
  margin: 0 auto;
  padding-top: 4.8rem;
  article {
    max-width: 848px;
    margin: 0 auto;
  }
  .firstSection {
    display: flex;
    padding: var(--Scale-3, 24px) var(--Scale-0, 0px);
    flex-direction: column;
    align-items: center;
    gap: var(--Scale-5, 40px);

    img {
      max-width: 100%;
    }
    .flexTittle {
      width: 100%;
      max-width: 848px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      h1 {
        color: var(--Grey-800, #2a2e40);

        /* Display/Medium/Regular */
        font-family: "Work Sans";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 76.8px */
      }
      p {
        width: 100%;
        overflow: hidden;
        color: var(--Grey-600, #495066);
        text-overflow: ellipsis;
        text-align: justify;

        /* Text/Extra Large/Regular */
        font-family: "Work Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.18px;
      }
    }
  }
  .flexBack {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: start;
    svg {
      width: 22px;
      height: 22px;
    }
    a {
      color: var(--Primary-500, #1b2cc1);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .post {
    display: flex;
    padding: var(--Scale-5, 40px) var(--Scale-0, 0px) var(--Scale-3, 24px)
      var(--Scale-0, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    h2 {
      color: var(--Grey-800, #2a2e40);

      /* Display/Small/Bold */
      font-family: "Work Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
    }
    p {
      color: var(--Grey-600, #495066);

      /* Title/Small/Regular */
      font-family: "Work Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 28px */
    }
    img {
      max-width: 100%;
    }
  }
  .secondSection {
    margin-bottom: 3.2rem;
    .cabecalho {
      padding: 3.2rem 0;

      h2 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 3.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }
    }
    .flexNewArticles {
      display: grid;
      grid-template-columns: repeat(4, 398px);
      gap: 2.4rem;
    }
  }

  @media (max-width: 915px) {
    .firstSection {
      .flexTittle {
        h1 {
          font-size: 36px;
        }
      }
    }
    .secondSection {
      margin-bottom: 0;
      .flexNewArticles {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
    }
  }
`;
