import React from "react";
import { StyledCarregando } from "../styles/Carregando";

function Carregando({ spinner }) {
  return (
    <StyledCarregando>
      {spinner && <span class="loader"></span>}
    </StyledCarregando>
  );
}

export default Carregando;
