import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
// import Nav from "./components/Nav";
import Homepage from "./pages/Homepage";
import NotFound from "./pages/NotFound";
import Office from "./pages/Office";
import { useState, useEffect } from "react";
import Concursos from "./pages/Concursos";
import About from "./pages/About";
import InnerCard from "./pages/InnerCard";
import SearchResults from "./pages/SearchResults";
import Payment from "./pages/Payment";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import ResetConfirm from "./pages/ResetConfirm";
import Activate from "./pages/Activate";
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./hocs/Layout";
import UserAccount from "./pages/UserAccount";
import Terms from "./pages/Terms";
import ProtectRouteLogin from "./utils/ProtectRouteLogin";
import ProtectRouteFree from "./utils/ProtectRouteFree";
import SucessPayment from "./pages/SucessPayment";
import ScrollTop from "./pages/ScrollTop";
import BlogHomepage from "./pages/BlogHomepage";
import InnerPostBlog from "./pages/InnerPostBlog";

function App() {
  // Controle de overlay
  const [overlay, setOverlay] = useState(false);
  // Variável que controla em que página o usuário estava antes de fazer o login, para voltar para ela
  const [urlAtual, setUrlAtual] = useState("/");
  const [tipoDePlano, setTipoDePlano] = useState(null);
  const [termoPesquisado, setTermoPesquisado] = useState("");

  // Variáveis para controle de conta
  const [freeAccount, setFreeAccount] = useState(true);
  const [freeAccountLogin, setFreeAccountLogin] = useState(false);
  const [premiumAccount, setPremiumAccount] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [whatPost, setWhatPost] = useState({});

  // ESTADO
  const [nacional, setNacional] = useState(false);
  const [saoPaulo, setSaoPaulo] = useState(false);
  const [rioDeJaneiro, setRioDeJaneiro] = useState(false);
  const [distritoFederal, setDistritoFederal] = useState(false);
  const [acre, setAcre] = useState(false);
  const [alagoas, setAlagoas] = useState(false);
  const [amapa, setAmapa] = useState(false);
  const [amazonas, setAmazonas] = useState(false);
  const [bahia, setBahia] = useState(false);
  const [ceara, setCeara] = useState(false);
  const [espiritoSanto, setEspiritoSanto] = useState(false);
  const [goias, setGoias] = useState(false);
  const [maranhao, setMaranhao] = useState(false);
  const [matoGrosso, setMatoGrosso] = useState(false);
  const [matoGrossoDoSul, setMatoGrossoDoSul] = useState(false);
  const [minasGerais, setMinasGerais] = useState(false);
  const [para, setPara] = useState(false);
  const [paraiba, setParaiba] = useState(false);
  const [parana, setParana] = useState(false);
  const [pernambuco, setPernambuco] = useState(false);
  const [piaui, setPiaui] = useState(false);
  const [rioGrandeDoNorte, setRioGrandeDoNorte] = useState(false);
  const [rioGrandeDoSul, setRioGrandeDoSul] = useState(false);
  const [rondonia, setRondonia] = useState(false);
  const [roraima, setRoraima] = useState(false);
  const [santaCatarina, setSantaCatarina] = useState(false);
  const [sergipe, setSergipe] = useState(false);
  const [tocantins, setTocantins] = useState(false);

  const state = {
    nacional,
    saoPaulo,
    rioDeJaneiro,
    distritoFederal,
    acre,
    alagoas,
    amapa,
    amazonas,
    bahia,
    ceara,
    espiritoSanto,
    goias,
    maranhao,
    matoGrosso,
    matoGrossoDoSul,
    minasGerais,
    para,
    paraiba,
    parana,
    pernambuco,
    piaui,
    rioGrandeDoNorte,
    rioGrandeDoSul,
    rondonia,
    roraima,
    santaCatarina,
    sergipe,
    tocantins,
  };
  const setState = {
    setNacional,
    setSaoPaulo,
    setRioDeJaneiro,
    setDistritoFederal,
    setAcre,
    setAlagoas,
    setAmapa,
    setAmazonas,
    setBahia,
    setCeara,
    setEspiritoSanto,
    setGoias,
    setMaranhao,
    setMatoGrosso,
    setMatoGrossoDoSul,
    setMinasGerais,
    setPara,
    setParaiba,
    setParana,
    setPernambuco,
    setPiaui,
    setRioGrandeDoNorte,
    setRioGrandeDoSul,
    setRondonia,
    setRoraima,
    setSantaCatarina,
    setSergipe,
    setTocantins,
  };

  // ETAPAS
  const [preInscricao, setPreInscricao] = useState(false);
  const [inscricoesAbertas, setInscricoesAbertas] = useState(false);
  const [inscricoesEncerradas, setInscricoesEncerradas] = useState(false);

  const stage = {
    preInscricao,
    inscricoesAbertas,
    inscricoesEncerradas,
  };
  const setStage = {
    setPreInscricao,
    setInscricoesAbertas,
    setInscricoesEncerradas,
  };

  // ESCOLARIDADE
  const [superior, setSuperior] = useState(false);
  const [fundamental, setFundamental] = useState(false);
  const [medio, setMedio] = useState(false);
  const [mestrado, setMestrado] = useState(false);
  const [doutorado, setDoutorado] = useState(false);

  const education = {
    superior,
    fundamental,
    medio,
    mestrado,
    doutorado,
  };

  const setEducation = {
    setSuperior,
    setFundamental,
    setMedio,
    setMestrado,
    setDoutorado,
  };

  // VAGAS
  const [cadastroReserva, setCadastroReserva] = useState(false);
  const [vagaUm, setVagaUm] = useState(false);
  const [vagaDoisDez, setVagaDoisDez] = useState(false);
  const [vagaDez, setVagaDez] = useState(false);
  const [vagaVinteCinco, setVagaVinteCinco] = useState(false);
  const [vagaCinquenta, setVagaCinquenta] = useState(false);
  const [vagaCem, setVagaCem] = useState(false);

  const vacancy = {
    cadastroReserva,
    vagaUm,
    vagaDoisDez,
    vagaDez,
    vagaVinteCinco,
    vagaCinquenta,
    vagaCem,
  };

  const setVacancy = {
    setCadastroReserva,
    setVagaUm,
    setVagaDoisDez,
    setVagaDez,
    setVagaVinteCinco,
    setVagaCinquenta,
    setVagaCem,
  };

  //SALARIO
  const [semRendimentos, setSemRendimentos] = useState(false);
  const [salario1, setSalario1] = useState(false);
  const [salario2, setSalario2] = useState(false);
  const [salario3, setSalario3] = useState(false);
  const [salario4, setSalario4] = useState(false);
  const [salario5, setSalario5] = useState(false);
  const [salario6, setSalario6] = useState(false);
  const [salario7, setSalario7] = useState(false);
  const [salario8, setSalario8] = useState(false);
  const [salario9, setSalario9] = useState(false);
  const [salario10, setSalario10] = useState(false);
  const [salario11, setSalario11] = useState(false);
  const [salario12, setSalario12] = useState(false);
  const [salario13, setSalario13] = useState(false);
  const [salario14, setSalario14] = useState(false);

  const wage = {
    semRendimentos,
    salario1,
    salario2,
    salario3,
    salario4,
    salario5,
    salario6,
    salario7,
    salario8,
    salario9,
    salario10,
    salario11,
    salario12,
    salario13,
    salario14,
  };

  const setWage = {
    setSemRendimentos,
    setSalario1,
    setSalario2,
    setSalario3,
    setSalario4,
    setSalario5,
    setSalario6,
    setSalario7,
    setSalario8,
    setSalario9,
    setSalario10,
    setSalario11,
    setSalario12,
    setSalario13,
    setSalario14,
  };

  // Resetando pesquisa
  const [searchReset, setSearchReset] = useState(false);

  useEffect(() => {
    if (tipoDePlano) {
      localStorage.setItem("tipoDePlano", tipoDePlano); // Salva o plano no localStorage
    }
  }, [tipoDePlano]);

  useEffect(() => {}, []);
  return (
    <Provider store={store}>
      <GlobalStyles />
      <BrowserRouter>
        <ScrollTop setOverlay={setOverlay} />
        {overlay && <div className="overlay" />}
        <Layout setUrlAtual={setUrlAtual}>
          <Routes>
            <Route
              path="/"
              element={
                <Homepage
                  setUrlAtual={setUrlAtual}
                  setTermoPesquisado={setTermoPesquisado}
                  setSearchReset={setSearchReset}
                />
              }
            />
            <Route
              path="/cargos"
              element={
                <Office
                  overlay={overlay}
                  setOverlay={setOverlay}
                  freeAccount={freeAccount}
                  setFreeAccount={setFreeAccount}
                  freeAccountLogin={freeAccountLogin}
                  setFreeAccountLogin={setFreeAccountLogin}
                  premiumAccount={premiumAccount}
                  setPremiumAccount={setPremiumAccount}
                  recarregar={recarregar}
                  setRecarregar={setRecarregar}
                  stage={stage}
                  setStage={setStage}
                  education={education}
                  setEducation={setEducation}
                  vacancy={vacancy}
                  setVacancy={setVacancy}
                  wage={wage}
                  setWage={setWage}
                  state={state}
                  setState={setState}
                  setSearchReset={setSearchReset}
                  searchReset={searchReset}
                  setUrlAtual={setUrlAtual}
                  setTermoPesquisado={setTermoPesquisado}
                />
              }
            />
            <Route
              path="/cargos/:pesquisa"
              element={
                <SearchResults
                  overlay={overlay}
                  setOverlay={setOverlay}
                  key={"offices"}
                  pagina={"offices"}
                  state={state}
                  setState={setState}
                  stage={stage}
                  setStage={setStage}
                  education={education}
                  setEducation={setEducation}
                  vacancy={vacancy}
                  setVacancy={setVacancy}
                  wage={wage}
                  setWage={setWage}
                  termoPesquisado={termoPesquisado}
                  setTermoPesquisado={setTermoPesquisado}
                  setUrlAtual={setUrlAtual}
                  setSearchReset={setSearchReset}
                  searchReset={searchReset}
                />
              }
            />
            <Route
              path="/concursos"
              element={
                <Concursos
                  overlay={overlay}
                  setOverlay={setOverlay}
                  recarregar={recarregar}
                  stage={stage}
                  setStage={setStage}
                  education={education}
                  setEducation={setEducation}
                  vacancy={vacancy}
                  setVacancy={setVacancy}
                  wage={wage}
                  setWage={setWage}
                  state={state}
                  setState={setState}
                  setSearchReset={setSearchReset}
                  searchReset={searchReset}
                  setUrlAtual={setUrlAtual}
                  setTermoPesquisado={setTermoPesquisado}
                  termoPesquisado={termoPesquisado}
                />
              }
            />
            <Route
              path="/concursos/:pesquisa"
              element={
                <SearchResults
                  overlay={overlay}
                  setOverlay={setOverlay}
                  key={"contests"}
                  pagina={"contests"}
                  state={state}
                  setState={setState}
                  stage={stage}
                  setStage={setStage}
                  education={education}
                  setEducation={setEducation}
                  vacancy={vacancy}
                  setVacancy={setVacancy}
                  wage={wage}
                  setWage={setWage}
                  termoPesquisado={termoPesquisado}
                  setTermoPesquisado={setTermoPesquisado}
                  setUrlAtual={setUrlAtual}
                  setSearchReset={setSearchReset}
                  searchReset={searchReset}
                />
              }
            />
            <Route
              path="/sobre"
              element={<About setUrlAtual={setUrlAtual} />}
            />
            <Route path="/concurso/:slug" element={<InnerCard />} />
            <Route element={<ProtectRouteFree />}>
              <Route
                path="/planos"
                element={
                  <Payment
                    setUrlAtual={setUrlAtual}
                    setTipoDePlano={setTipoDePlano}
                    tipoDePlano={tipoDePlano}
                  />
                }
              />
            </Route>

            <Route
              exact
              path="/login"
              element={<Login urlAtual={urlAtual} tipoDePlano={tipoDePlano} />}
            />
            <Route exact path="/cadastro" element={<SignUp />} />
            <Route exact path="/resetar-senha" element={<ResetPassword />} />
            <Route
              exact
              path="/resetar-senha/confirmar/:uid/:token"
              element={<ResetConfirm />}
            />
            <Route exact path="/ativar/:uid/:token" element={<Activate />} />
            <Route element={<ProtectRouteLogin />}>
              <Route
                element={<UserAccount urlAtual={urlAtual} />}
                path="/conta"
              />
            </Route>
            <Route path="/sucesso" element={<SucessPayment />} />

            <Route path="/termos" element={<Terms urlAtual={urlAtual} />} />
            <Route
              path="/blog"
              element={
                <BlogHomepage
                  setUrlAtual={setUrlAtual}
                  setTermoPesquisado={setTermoPesquisado}
                  setSearchReset={setSearchReset}
                />
              }
            />
            <Route
              path="/blog/:id"
              element={
                <InnerPostBlog
                  urlAtual={urlAtual}
                  whatPost={whatPost}
                  setUrlAtual={setUrlAtual}
                  setTermoPesquisado={setTermoPesquisado}
                  setSearchReset={setSearchReset}
                />
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
