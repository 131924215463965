import styled from "styled-components";

export const StyledSearchBox = styled.section`
  display: flex;
  padding: var(--Scale-12, 96px) 0;
  margin: 0 auto;
  flex-direction: column;
  max-width: 95%;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 309px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .flexSearchBlog {
      display: flex;
      align-items: center;
      max-width: 632px;
      flex-direction: column;
      gap: 10px;
    }
    .tittleAndText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      h2 {
        color: var(--Supporting-white, #fff);
        text-align: center;

        /* Title/Small/Bold */
        font-family: "Work Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 26px */
      }
      p {
        color: var(--Supporting-white, #fff);
        text-align: center;

        /* Text/Extra Large/Regular */
        font-family: "Work Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.18px;
      }
    }
  }

  @media (max-width: 545px) {
    padding: 48px 0;
    .box {
      .flexSearchBlog {
        max-width: 304px;
      }
    }
  }
`;
