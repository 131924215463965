import React from "react";
import { StyledBlogCard } from "../styles/BlogCard";
import { useNavigate } from "react-router-dom";

function BlogCard({ id, img, imgName, tittle, subttitle }) {
  const Navigate = useNavigate();
  return (
    <StyledBlogCard onClick={() => Navigate(`/blog/${id}`)}>
      <img src={img} alt={imgName} />
      <h3>{tittle}</h3>
      <p>{subttitle}</p>
    </StyledBlogCard>
  );
}

export default BlogCard;
